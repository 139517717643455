/* 
*----------Background-White----------*
:root {
    --bg1: #E8EAEC;
    --text-color: #979797;
    --color: #000000;
    --tcolor: rgb(119 119 119);
  
    --boxShadow:
        7px 7px 15px #45566754,
        -8px -8px 15px rgb(81 101 121 / 4%),
        inset -5px -4px 13px 0 rgb(81 101 121 / 35%),
        inset 6px 7px 12px 0 rgb(255 255 255 / 94%);
  
    --boxShadow2:
        inset 4px 4px 8px rgb(189 200 213),
        inset -4px -4px 8px rgb(255 255 255);
  
    --boxShadow3:
        4px 4px 8px rgb(189 200 213),
        -4px -4px 8px rgb(255 255 255);
  
    --sbShadow:
        5px 5px 7px rgb(0 0 0 / 25%),
        inset 2px 2px 5px rgb(255 255 255 / 25%),
        inset -3px -3px 5px rgb(0 0 0 / 12%);
  }
  
  *----------Background-Dark----------*
  body.dark-mode {
    --bg1: #2F363E;
    --text-color: rgb(255, 208, 0.25);
    --color: #FFFFFF;
    --tcolor: rgb(243, 243, 243);
  
    --boxShadow:
        5px 5px 15px rgba(0, 0, 0, 0.25),
        5px 15px 15px rgba(0, 0, 0, 0.25),
        inset 5px 5px 10px rgba(0, 0, 0, 0.25),
        inset 5px 5px 20px rgba(255, 255, 255, 0.2),
        inset -5px -5px 15px rgba(0, 0, 0, 0.75);
  
    --boxShadow2:
        inset -3px -3px 6px rgb(73, 78, 83),
        inset 3px 3px 6px rgb(32, 28, 28);
  
    --boxShadow3:
        -3px -3px 6px rgb(73, 78, 83),
        3px 3px 6px rgb(32, 28, 28);
  
    --sbShadow:
        5px 5px 7px rgb(0 0 0 / 25%),
        inset 2px 2px 5px rgb(255 255 255 / 25%),
        inset -3px -3px 5px rgb(0 0 0 / 50%);
  }
   */

.custom-alert {
    max-width: 320px;
    width: 100% !important;
    margin: 0 auto;
    position: fixed !important;
    top: 13%;
    z-index: 9999999999 !important;
    left: 88%;
    transform: translate(-50%, -50%) !important;
    background-color: #e8eaec !important;
    border: none !important;
    box-shadow: var(--boxShadow3) !important;
    display: none;
}

.error-text {
    color: red !important;
}

.text-success {
    color: green !important;
}

.custom-alert.dark-mode {
    background-color: #2f363e !important;
    box-shadow: var(--boxShadow2) !important;
}

.custom-alert.active {
    display: block !important;
}

.custom-alert {
    margin: 0 !important;
    margin-top: 4px !important;
}

.my-logo {
    align-content: center;
    color: #f7f3f3;
    display: block;
    width: 100%;
}

.skillsContainer {
    text-align: center;
    font-size: 0.7rem;
    padding: 20px 0;
    margin-bottom: 40px;
}

.skillsGrid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-column-gap: 50px;
    grid-row-gap: 50px;
}

.skillsGrid li {
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-box-shadow: 0px 4px 8px rgba(134, 151, 168, 0.1);
    box-shadow: 0px 4px 8px rgba(134, 151, 168, 0.1);
    border: 1px solid #e1e1e1;
}

.skillsGrid li:hover {
    -webkit-box-shadow: 0 10px 28px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 28px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}

.skillsGrid li p {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
}

.skillsGrid li img {
    width: 35px;
    height: 35px;
}

@media screen and (max-width: 768px) {
    .skillsGrid {
        row-gap: 15px;
        -webkit-column-gap: 15px;
        column-gap: 15px;
    }
}

.sectionHead {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}