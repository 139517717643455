@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,600,700,800");

/**----------Background-White----------**/
:root {

    --Onyx: #212121;
    --White: #ffffff;
    --Light-Grayish: #E8EAEC;
    --Dark-Grayish: #2F363E;
    --Grass-Green: #099109;
    --Spice-Orange: #e95420;

    --bg: var(--Light-Grayish);
    --text-color-main: var(--Onyx);
    --text-color-sec: var(--Dark-Grayish);
    --link: var(--Grass-Green);
    --link-hover: var(--Spice-Orange);

    --boxShadow: 7px 7px 15px #45566754,
    -8px -8px 15px rgb(81 101 121 / 4%),
    inset -5px -4px 13px 0 rgb(81 101 121 / 35%),
    inset 6px 7px 12px 0 rgb(255 255 255 / 94%);

    --boxShadow2: inset 4px 4px 8px rgb(189 200 213),
    inset -4px -4px 8px rgb(255 255 255);

    --ErrorBox: inset 4px 4px 8px rgb(206, 120, 120),
    inset -4px -4px 8px rgb(222, 17, 17);

    --boxShadow3: 4px 4px 8px rgb(189 200 213),
    -4px -4px 8px rgb(255 255 255);

    --sbShadow: 5px 5px 7px rgb(0 0 0 / 25%),
    inset 2px 2px 5px rgb(255 255 255 / 25%),
    inset -3px -3px 5px rgb(0 0 0 / 12%);
}

/**----------Background-Dark----------**/
body.dark-mode {

    --Onyx: #212121;
    --Light-Grayish: #E8EAEC;
    --White: #ffffff;
    --Dark-Grayish: #2F363E;
    --Grass-Green: #099109;
    --Spice-Orange: #e95420;

    --bg: var(--Dark-Grayish);
    --text-color-main: var(--White);
    --text-color-sec: var(--Light-Grayish);
    --link: var(--Grass-Green);
    --link-hover: var(--Spice-Orange);

    --boxShadow: 5px 5px 15px rgba(0, 0, 0, 0.25),
    5px 15px 15px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(0, 0, 0, 0.25),
    inset 5px 5px 20px rgba(255, 255, 255, 0.2),
    inset -5px -5px 15px rgba(0, 0, 0, 0.75);

    --boxShadow2: inset -3px -3px 6px rgb(73, 78, 83),
    inset 3px 3px 6px rgb(32, 28, 28);

    --boxShadow3: -3px -3px 6px rgb(73, 78, 83),
    3px 3px 6px rgb(32, 28, 28);

    --sbShadow: 5px 5px 7px rgb(0 0 0 / 25%),
    inset 2px 2px 5px rgb(255 255 255 / 25%),
    inset -3px -3px 5px rgb(0 0 0 / 50%);
}

body {
    background: var(--bg) !important;
    padding-top: 70px;
    font-family: "Maven Pro", sans-serif !important;
}

.full-screen {
    padding: 4rem 0;
}

.small-text {
    color: #5b5b5b;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 50px;
    letter-spacing: 0.2px;
    box-shadow: var(--boxShadow3);
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

a {
    color: var(--link);
    font-weight: bold;
    text-decoration: none !important;
    transition: all 0.2s ease;
}

a:hover {
    color: var(--link-hover) !important;
}

.navbar-brand .uil {
    font-size: 40px;
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color-sec);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
    letter-spacing: -1px !important;
}

h1 {
    color: #212121 !important;
    font-size: 2.8em !important;
    margin: 30px 0 !important;
}

h2 {
    color: #353535 !important;
    font-size: 2.4em !important;
    font-weight: bold !important;
}

h3 {
    color: #484848 !important;
}

h3,
b, strong {
    font-weight: bold !important;
}

.custom-btn {
    border: none !important;
    background: var(--bg) !important;
    color: var(--link) !important;
    font-weight: bold !important;
    border-radius: 50px !important;
    padding: 13px 29px !important;
    font-size: 14px !important;
    line-height: normal !important;
    overflow: hidden !important;
    transition: all 0.4s ease !important;
    box-shadow: var(--sbShadow) !important;
}

.custom-btn:hover {
    color: var(--link-hover) !important;
}

.custom-btn.custom-btn-bg {
    background: #474559 !important;
    color: #ffffff !important;
}

.custom-btn.custom-btn-bg:hover {
    background: var(--link-hover) !important;
    color: #ffffff !important;
}

.animated {
    position: relative !important;
}

.animated-info {
    display: inline-block !important;
    vertical-align: top !important;
    margin-top: 5px !important;
    min-width: 300px !important;
    position: relative !important;
}

@media screen and (max-width: 767px) {
    .animated .mr-2 {
        font-size: 30px;
    }

    .animated-info {
        margin-top: 14px !important;
    }

    .animated .h3 {
        font-size: 27px;
    }
}

@media screen and (max-width: 390px) {
    .animated .mr-2 {
        font-size: 25px;
    }

    .animated-info {
        margin-top: 19px !important;
    }

    .animated .h3 {
        font-size: 20px;
    }
}

.animated-item {
    color: var(--Spice-Orange) !important;
}

.animated-item {
    font-size: 38px;
    line-height: inherit;
    display: block;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    animation: BottomTotop 6s linear infinite 0s;
}

.animated-item:nth-child(2n+2) {
    animation-delay: 2s;
}

.animated-item:nth-child(3n+3) {
    animation-delay: 4s;
}

@keyframes BottomTotop {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        transform: translateY(5px);
    }
    10% {
        opacity: 1;
        transform: translateY(0px);
    }
    25% {
        opacity: 1;
        transform: translateY(0px);
    }
    30% {
        opacity: 0;
        transform: translateY(5px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    will-change: transform;
    background: var(--bg) !important;
    box-shadow: var(--boxShadow3);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transition: transform 300ms linear;
}

.headroom {
    z-index: 999 !important;
}

.headroom--unpinned {
    transform: translate(0, -150%);
}

@media screen and (max-width: 767px) {
    .headroom--unpinned .navbar-collapse {
        transform: translate(0, 10%);
    }
}

.headroom--pinned {
    transform: translate(0, 0);
}

.headroom--unfixed .navbar {
    transform: translate(0, -80%) !important;
    box-shadow: none !important;
    background: transparent !important;
}

@media screen and (max-width: 767px) {
    .headroom--unfixed .navbar-collapse {
        transform: translate(0, -2%) !important;
    }
}

.navbar-brand {
    font-weight: bold;
}

.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0 20px !important;
}

.nav-link {
    font-weight: bold !important;
    font-size: 16px !important;
    overflow: hidden !important;
    cursor: pointer !important;
}

.nav-link span {
    position: relative;
    display: inline-block;
    transition: transform 0.3s;
}

.nav-link.active {
    color: var(--Spice-Orange) !important;
}

.nav-link span:before {
    position: absolute;
    top: 100%;
    content: attr(data-hover);
    transform: translate3d(0, 0, 0);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: var(--link-hover);
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link:focus span,
.navbar-light .navbar-nav .nav-link:hover span {
    transform: translateY(-100%);
}

.navbar-light .navbar-toggler-icon {
    background: none;
}

.navbar-toggler {
    border: 0;
    outline: none;
    cursor: pointer;
}

.navbar-toggler .line {
    width: 20px;
    display: block;
    height: 2px;
    background: black;
    margin: 2px 0;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:first-child {
    transform: rotate(45deg);
    top: 6px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:nth-child(2) {
    display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:last-child {
    transform: rotate(-45deg);
    bottom: 1px;
}

.navbar-toggler .navbar-toggler-icon {
    background: #212121;
    display: block;
    width: 100%;
    height: 2px;
    margin: 5px 0;
    transition: all 0.4s ease;
    position: relative;
}

.copyright-text {
    font-size: 16px;
    font-weight: normal;
    display: block;
}

.color-mode {
    top: 15px;
    right: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: var(--boxShadow3);
    border-radius: 50%;
}

.color-mode-icon {
    /* position: relative; */
    right: 6px;
}

.color-mode-icon:after {
    font-family: 'unicons';
    content: '\ea9f';
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
}

.color-mode-icon.active:after {
    font-family: 'unicons';
    content: '\eb65';
    font-size: 30px;
    color: var(--text-color-main);
}

.dark-mode {
    background: var(--bg) !important;
}

.dark-mode .navbar-light .navbar-nav .nav-link {
    color: var(--text-color-sec) !important;
}

.dark-mode .navbar-light .navbar-nav .nav-link.active {
    color: var(--Spice-Orange) !important;
}

.dark-mode .skillsGrid li {
    background: #2F363E !important;
    border: 1px solid #6b6b6b;
}

.dark-mode .skillsGrid li p {
    color: white !important;
}

.dark-mode .navbar-light .navbar-nav .nav-link:hover {
    color: var(--link-hover) !important;
}

.dark-mode .navbar[class*="headroom--not-top"] {
    background: var(--bg) !important;
    border-bottom: 1px solid #1f1f1f !important;
}

.dark-mode .small-text {
    background: #0d0c1549 !important;
    color: var(--text-color-main) !important;
}

.dark-mode .feature-card .uil,
.dark-mode .navbar-light .navbar-brand,
.dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h4, .dark-mode h5, .dark-mode h6,
.dark-mode .color-mode {
    color: var(--text-color-main) !important;
}

.dark-mode .nav-link.active {
    color: var(--Spice-Orange) !important;
}

.dark-mode .owl-carousel .owl-nav button.owl-next,
.dark-mode .owl-carousel .owl-nav button.owl-prev,
.dark-mode .owl-carousel button.owl-dot {
    color: var(--text-color-main) !important;
}

.timeline-wrapper {
    position: relative;
    padding: 22px 0;
}

.timeline-wrapper:last-child:before {
    height: 0;
}

.timeline-wrapper:before {
    content: "";
    background: var(--text-color-sec);
    width: 3px;
    height: 100%;
    position: absolute;
    left: 38px;
}

.timeline-yr {
    background: #474559;
    border-radius: 100%;
    position: absolute;
    width: 75px;
    height: 75px;
    line-height: 75px;
    text-align: center;
    box-shadow: var(--sbShadow);
}

.timeline-yr span {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: block;
    line-height: 75px;
}

.timeline-info {
    display: inline-block;
    vertical-align: top;
    max-width: 432px;
    margin-left: 6em;
}

.timeline-info small {
    color: var(--text-color-sec);
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}

.project-link {
    color: var(--link) !important;
    padding: 0 3px;
}

.go-contact {
    font-weight: 600;
    color: var(--link);
}

.owl-carousel .owl-nav span {
    display: none;
}

.owl-carousel .owl-nav .owl-prev:before,
.owl-carousel .owl-nav .owl-next:before {
    background: none;
    padding: 0;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: 'unicons';
    font-size: 100px;
    line-height: normal;
}

.owl-carousel .owl-nav .owl-prev:before {
    content: '\e833';
    left: -45px;
}

.owl-carousel .owl-nav .owl-next:before {
    content: '\e836';
    right: -45px;
}

.owl-theme .owl-nav [class*=owl-] {
    background: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    font-size: inherit;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    color: var(--link-hover) !important;
}

.owl-theme .owl-dots .owl-dot {
    outline: none;
}

.owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #5b5b5b;
}

.google-map iframe {
    width: 100%;
}

.fullname {
    margin-top: 15px !important;
    margin-bottom: 1rem !important;
    color: var(--text-color-main);
}

.copy-number {
    font-weight: 600;
    color: var(--link) !important;
    padding: 4px 4px 4px 0;
}

.copy-number:hover {
    cursor: pointer;
}

.contact-form {
    position: relative;
}

@media screen and (max-width: 767px) {
    .contact-form h2 {
        font-size: 1.7rem !important;
    }
}

@media screen and (max-width: 425px) {
    .contact-form h2 {
        font-size: 1.5rem !important;
    }
}

.contact-form .form-control {
    background: transparent;
    outline: none;
    font-weight: bold;
    margin: 16px 0;
}

#email, #name, #message {
    padding: 15px;
    border: none;
    border-radius: 25px;
    box-shadow: var(--boxShadow2) !important;
}

.contact-form .form-control.error {
    border: 2px solid red !important;
}

.dark-mode .contact-form .form-control {
    color: white !important;
}


#email:focus, #name:focus, #message:focus {
    background: initial;
}

.contact-form .form-control:not(textarea) {
    height: 48px;
}

.contact-form .form-control:hover, .contact-form .form-control:focus {
    border-color: var(--link-hover);
}

.contact-form .submit-btn {
    background: #474559;
    border-radius: 50px;
    color: var(--White) !important;
    font-weight: bold;
    border: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    box-shadow: var(--sbShadow);
}

.contact-form .submit-btn.loading {
    opacity: .5;
    pointer-events: none;
    cursor: default;
}

.contact-form .submit-btn:hover {
    background: var(--link-hover);
}

.contact-info {
    border-radius: 25px;
    box-shadow: var(--boxShadow3);
    background: var(--bg);
    position: relative;
    bottom: 8px;
}

.social-links .uil {
    color: #f7f3f3;
    font-size: 30px;
    display: block;
    margin: 15px 0;
    padding: 8px;
    border-radius: 50%;
    box-shadow: var(--sbShadow);
}

.social-links li:nth-child(1) .uil {
    background: linear-gradient(315deg, #0088cc -7.64%, #00C6FF 104.5%);
}

.social-links li:nth-child(1):hover .uil {
    color: #0088cc !important;
    background: linear-gradient(to bottom, #E8EAEC, rgba(189, 200, 213, 0.795));
    text-shadow: 2px 2px 3px #B8B9BE, -2px -2px 3px #FFFFFF;
}

.social-links li:nth-child(2) .uil {
    background: linear-gradient(
            45deg,
            #f09433 0%,
            #e6683c 25%,
            #dc2743 50%,
            #cc2366 75%,
            #bc1888 100%
    );
}

.social-links li:nth-child(2):hover .uil {
    color: #cc2366 !important;
    background: linear-gradient(to bottom, #E8EAEC, rgba(189, 200, 213, 0.795));
    text-shadow: 2px 2px 3px #B8B9BE, -2px -2px 3px #FFFFFF;
}

.social-links li:nth-child(3) .uil {
    background: linear-gradient(315deg, #cc7367 -17.64%, #ea4335 101.5%);
}

.social-links li:nth-child(3):hover .uil {
    color: #ea4335 !important;
    background: linear-gradient(to bottom, #E8EAEC, rgba(189, 200, 213, 0.795));
    text-shadow: 2px 2px 3px #B8B9BE, -2px -2px 3px #FFFFFF;
}

.social-links li:nth-child(4) .uil {
    background: linear-gradient(315deg, #0072b1 -7.64%, #00C6FF 104.5%);
}

.social-links li:nth-child(4):hover .uil {
    color: #0072b1 !important;
    background: linear-gradient(to bottom, #E8EAEC, rgba(189, 200, 213, 0.795));
    text-shadow: 2px 2px 3px #B8B9BE, -2px -2px 3px #FFFFFF;
}

.social-links li:nth-child(5) .uil {
    background: linear-gradient(315deg, #a838bb -7.64%, #51287c 104.5%);
}

.social-links li:nth-child(5):hover .uil {
    color: #171515 !important;
    background: linear-gradient(to bottom, #E8EAEC, rgba(189, 200, 213, 0.795));
    text-shadow: 2px 2px 3px #B8B9BE, -2px -2px 3px #FFFFFF;
}

@media (min-width: 1270px) {
    .owl-theme .owl-dots {
        position: relative;
        bottom: 50px;
    }
}

@media (max-width: 991px) {
    .full-screen {
        padding-bottom: 4rem;
    }

    .color-mode {
        display: none;
    }

    .about-image {
        margin-top: 4em;
    }

    .mobile-mt-2,
    .contact-form {
        margin-top: 2em;
    }

    .contact-info {
        padding: 0 2rem;
    }
}

i.close {
    display: none;
}

@media (max-width: 767px) {
    h1 {
        font-size: 2.4em;
    }

    h2 {
        font-size: 2em;
    }

    .animated-item {
        font-size: 30px;
    }

    i.close {
        display: block;
        font-size: 25px;
        position: absolute;
        right: 10px;
        top: 25px;
    }

    .navbar-collapse {
        background: #ffffff;
        position: fixed;
        top: 0;
        left: -100%;
        width: 300px;
        height: 100vh;
        padding: 20px 10px;
        transition: 0.3s ease;
    }

    .navbar-collapse .nav-item {
        margin: 10px 0;
    }

    .navbar-collapse.active {
        left: 0;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding: 3px 20px;
    }
}


@media (max-width: 580px) {
    .animated-info {
        min-width: 200px !important;
    }

    .custom-btn-group {
        text-align: center;
    }

    .custom-btn {
        display: block;
        margin: 10px 0;
    }

    .owl-theme .owl-nav {
        display: none;
    }

    .timeline-info small {
        display: block;
        margin: 10px 0 0 0;
    }
}

@media screen and (max-width: 390px) {
    .animated-item {
        font-size: 25px;
    }
}

@media (max-width: 320px) {
    .animated-text {
        margin-top: 0;
    }

    .about-text {
        text-align: center;
    }

    .full-screen {
        padding: 4rem 0;
    }

    .mobile-block {
        display: block;
    }

    .contact-info {
        flex-direction: column;
    }

    .social-links li {
        display: inline-block;
        vertical-align: top;
    }
}
