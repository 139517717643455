.swiper {
    width: 100%;
    z-index: 1;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 767px) {
    .title h2 {
        font-size: 1.7rem !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }
}

@media screen and (max-width: 425px) {
    .title h2 {
        font-size: 1.5rem !important;
        margin-bottom: 20px;
    }
}